import { rj } from 'redux-rocketjump'
import { combineReducers } from 'redux'
import { fork } from 'redux-saga/effects'
import positionalArgs from 'redux-rocketjump/plugins/positionalArgs'
import request from 'superagent'
// import { authApiCall, withToken } from './auth'
import { API_URL } from '../constants'

const GET_COLLECTIONS = 'GET_COLLECTIONS'
export const {
  actions: {
    load: loadCollections,
    unload: unloadCollections,
  },
  selectors: {
    getData: getCollections,
  },
  saga: collectionsSaga,
  reducer: collectionsReducer,
} = rj({
  type: GET_COLLECTIONS,
  state: 'paintings.collections',
  api: () =>  request.get(`${API_URL}/collections/`)
    .then(({ body }) => body)
})()

const GET_COLLECTION = 'GET_COLLECTION'
export const {
  actions: {
    load: loadCollection,
    unload: unloadCollection,
  },
  selectors: {
    getData: getCollection,
  },
  saga: collectionSaga,
  reducer: collectionReducer,
} = rj(positionalArgs(), {
  type: GET_COLLECTION,
  state: 'paintings.collection',
  api: (id) =>  request.get(`${API_URL}/collections/${id}/`)
    .then(({ body }) => body)
})()

const GET_ARTWORK = 'GET_ARTWORK'
export const {
  actions: {
    load: loadArtwork,
    unload: unloadArtwork,
  },
  selectors: {
    getData: getArtwork,
    isLoading: getArtworkLoading,
  },
  saga: artworkSaga,
  reducer: artworkReducer,
} = rj(positionalArgs(), {
  type: GET_ARTWORK,
  state: 'paintings.artwork',
  api: (id) =>  request.get(`${API_URL}/artworks/${id}/`)
    .then(({ body }) => body)
})()


export const reducer = combineReducers({
  collections: collectionsReducer,
  collection: collectionReducer,
  artwork: artworkReducer,
})

export function* saga() {
  yield fork(collectionsSaga)
  yield fork(collectionSaga)
  yield fork(artworkSaga)
}
