import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import PhotoSet from '../components/PhotoSet'
import { Container } from 'reactstrap'
import { loadCollection, unloadCollection, getCollection } from '../state/paintings'


class Collection extends React.PureComponent {

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    console.log(1, params)
    this.props.loadCollection(params.slug)
  }

  componentWillUnmount() {
    this.props.unloadCollection()
  }

  render() {
    const { collection } = this.props

    return <Container
        className="with-topbar with-footer d-flex flex-column align-items-center
        justify-content-center min-100vh">
      <TopBar/>

      { collection && <React.Fragment>
        <h3 className="m-4">{collection.title}</h3>
        <div className="w-100">

          <PhotoSet
            margin={10}
            size={240}
            minSize={240}
            showTitles={true}
            // showTitlesHover={true}
            pics={collection.artworks.map(artwork => ({
              link: `/collections/${collection.slug}/artwork/${artwork.slug}`,
              src: artwork.image,
              title: artwork.title,
          }))}/>
        </div>

      </React.Fragment>}


      <Footer/>
    </Container>
  }
}

const mapStateToProps = state => ({
  collection: getCollection(state),
})

export default connect(
  mapStateToProps,
  { loadCollection, unloadCollection }
)(Collection)
