import React from 'react'
import PropTypes from 'prop-types'
import { chunk } from 'lodash'
import 'bootstrap/dist/css/bootstrap.min.css'
import './PhotoSet.css'
import { Link } from 'react-router-dom'


class PhotoSet extends React.PureComponent {

  state = {
    containerWidth: 0,
    containerHeight: 0,
    realSize: 0,
    realItemsPerRow: 0,
    hoverIdx: null,
  }

  measure = () => {
    const { size, minSize, maxItemsPerRow, margin } = this.props
    const rect = this.container.getBoundingClientRect()
    const { width, height } = rect
    let realSize = size
    let realItemsPerRow = maxItemsPerRow

    if (width) {

      if(!maxItemsPerRow){
        realItemsPerRow = width / ( minSize + margin * 2 )
        realSize = minSize
      } else {
        const requiredSize = maxItemsPerRow * size
        if (requiredSize > width) {
          const margins = margin * 2 * maxItemsPerRow
          realSize = (width - margins) / maxItemsPerRow
          if(realSize < minSize) {
            realSize = minSize
            realItemsPerRow = width / ( realSize + margin * 2 )
          }
        }
      }


    }


    this.setState({
      containerWidth: width,
      containerHeight: height,
      realSize: realSize,
      realItemsPerRow: realItemsPerRow,
    })

  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.measure)
  }

  componentWillReceiveProps(nextProps){

  }

  componentDidMount(){
    this.measure()
    window.addEventListener('resize', this.measure, false)
  }

  render(){
    const { maxItemsPerRow, pics, size, margin, cover, showTitles, showTitlesHover } = this.props
    const { containerWidth, containerHeight, realSize, realItemsPerRow } = this.state
    const rows = chunk(pics, realItemsPerRow)

    return (
      <div className="photoset w-100 h-100 bg-black" ref={node=>this.container = node}>
        {containerWidth && rows.map((row, i) => (
          <div key={i}>
            <div className="photoset-row" >
              { row.map((pic, j) => (
                <div key={j} >
                  <Link to={`${pic.link}`}>
                  <div className="photoset-cell"
                    style={{
                      width: realSize,
                      height: realSize,
                      margin: margin,

                    }}>
                    { cover && <img alt={pic.title} src={pic.src} style={{objectFit:'cover', width: realSize, height: realSize }}/>}
                    { !cover && <img alt={pic.title} src={pic.src} style={{objectFit:'scale-down', width: realSize, height: realSize }}/>}

                    {showTitlesHover && (
                      <div className="photoset-title-hover" style={{width: realSize}}>
                        {pic.title}
                      </div>
                    )}

                  </div>
                  </Link>

                  {showTitles && <div className="photoset-title" style={{width: realSize, margin: margin}}>
                    {pic.title}
                  </div>}
              </div>
              ))}
            </div>

          </div>
        ))}
      </div>
    )
  }
}

PhotoSet.propTypes = {
  pics: PropTypes.array,
  // cover: PropTypes.boolean,
  // showTitles: PropTypes.boolean,
};

PhotoSet.defaultProps = {
  pics: [],
  maxItemsPerRow: 5,
  size: 180,
  minSize: 150,
  margin: 5,
  cover: true,
  showTitles: false,
  showTitlesHover: false,
}


export default PhotoSet
