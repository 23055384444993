import React, { Fragment} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { geoPath } from 'd3-geo'
import { get } from 'lodash'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'font-awesome/css/font-awesome.min.css'
import './photo.css'

const geoGenerator = geoPath()


class Popover extends React.PureComponent {
  render(){
    const { title, desc, thumb, url, image ,dx, dy, measureCount, imgWidth, imgHeight } = this.props

    const imageRect = image.getBoundingClientRect()
    const centerX = imageRect.x + imageRect.width / 2
    const centerY = imageRect.y + imageRect.height / 2
    const x = (- dx ) * (imgWidth / image.naturalWidth) + centerX
    const y = (- dy ) * (imgHeight / image.naturalHeight) + centerY

    return (
      <div style={{ top:y, left: x}} className="photo-popover">
        <div>
          <h1>Title</h1>
        </div>
        <div>
          <a href="#">Link here</a>
        </div>
        <div>
          <img className="img-fluid"
            style={{width:imgWidth/3 }}
            src="https://source.unsplash.com/random/300x200"/>
        </div>
        <div>
          <p>Desc here</p>
        </div>

      </div>
    )
  }
}




export default class Photo extends React.PureComponent {

  state = {
    showInfo: false,
    showAnnotations: false,
    containerHeight: 0,
    containerWidth: 0,
    naturalHeight: 0,
    naturalWidth: 0,
    popovers:[],
    measured: false,

  }

  photoContainer = null
  photo = null
  annotationsRefs = {}
  measureCount = 0

  toggleShowInfo = (e) => {
    this.setState({showInfo: !this.state.showInfo}, ()=>{this.measure()})

  }

  toggleShowAnnotations = (e) => {
    this.setState({showAnnotations: !this.state.showAnnotations})
  }

  togglePopover = (point) => (e) => {
    const rect = e.target.getBoundingClientRect()
    const imageNodeRect = this.photo.getBoundingClientRect()
    const centerX = imageNodeRect.x + imageNodeRect.width / 2
    const centerY = imageNodeRect.y + imageNodeRect.height / 2

    const x = rect.x + rect.width / 2
    const y = rect.y + rect.height / 2

    const dx = (centerX - x) / (this.photo.width / this.photo.naturalWidth)
    const dy = (centerY - y) / (this.photo.height / this.photo.naturalHeight)

    const popover = {
      title: 'hey',
      dx,
      dy,
    }
    this.setState({popovers: [popover]})
  }

  measure = () => {
    const containerHeight = this.props.sideInfo ? this.photoContainer.offsetHeight  : this.photoContainer.offsetHeight// - 70
    const containerWidth = this.photoContainer.offsetWidth
    const img = new Image()
    const that = this
    img.addEventListener('load', function() {
	     const naturalHeight = this.naturalHeight
       const naturalWidth = this.naturalWidth

       const imgRatio = naturalWidth / naturalHeight
       const containerRatio = containerWidth / containerHeight

       let imgWidth
       let imgHeight

       if(imgRatio < containerRatio){
         imgHeight = containerHeight
         imgWidth = imgRatio * imgHeight
       } else {
         imgWidth = containerWidth
         imgHeight = imgWidth / imgRatio
       }
       that.measureCount += 1
       that.setState({
         containerHeight, containerWidth, imgHeight, imgWidth,
         naturalHeight, naturalWidth,
         measured: true,
       })
    })
    img.src = this.props.src
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.measure)
  }

  componentWillReceiveProps(nextProps){
    // if(nextProps.showInfo !== this.props.showInfo){
    //   console.log("xxxx")
    //   this.measure()
    // }
  }

  componentDidMount(){
    this.measure()
    window.addEventListener('resize', this.measure, false)
  }

  render(){
    const { src, title, description, date, location, annotations, sideInfo } = this.props
    const { showInfo, showAnnotations, imgHeight, imgWidth, popovers, measured } = this.state


    const points = annotations.filter(a => get(a, 'geometry.type') === 'Point')

    return <div className="photo-frame" style={{opacity: !measured ? 0: undefined}}>

      <div className="photo-container">
        { sideInfo && showInfo && (
          <div className="photo-info">
            <h1>{title}</h1>
            <div className="photo-info-description">
            <p  className="font-14">{description}</p>
            </div>
            {date && <div className="text-small">
              <i className="fa fa-clock-o"></i>{' '}
              {date}
            </div>}
            {location && <div className="text-small">
              <i className="fa fa-map-marker"></i>{' '}
              {location}
            </div>}

          </div>
        )}
        <div className="photo-image photo-background" ref={node => this.photoContainer=node}>
          <img className="photo" src={src} style={{height: imgHeight, width: imgWidth}} ref={node => this.photo=node}/>
          { showAnnotations  && (
            <svg className="photo-annotations" style={{height: imgHeight, width: imgWidth}} viewBox={`0 0 ${this.state.naturalWidth} ${this.state.naturalHeight}`}>
              { points.map((point, i) => (
                <circle
                  key={i} className="photo-annotation-circle"
                  cx={point.geometry.coordinates[0]}
                  cy={point.geometry.coordinates[1]}
                  r={10}
                  onClick={this.togglePopover(point)}
                />
              ))}
            </svg>
          )}

          { !sideInfo && showInfo && (
            <Fragment>
            <div className="photo-info-overlay">
              {title}
            </div>

          <div className="photo-calendar-info">
            {date && <div className="text-small">
              <i className="fa fa-clock-o"></i>{' '}
              {date}
            </div>}
          </div>

          <div className="photo-location-info">
            {location && <div className="text-small">
              <i className="fa fa-map-marker"></i>{' '}
              {location}
            </div>}
          </div>
          </Fragment>

          )}



        </div>

      </div>

      {/* <div className="photo-objects">xxxxx</div> */}

      <div className="photo-toolbar text-center">
        <div>
          <i className={`fa fa-info ${showInfo ? 'icon-active' : ''}`} onClick={this.toggleShowInfo}></i>
        </div>
        {annotations && annotations.length > 0 && <div>
          <i className={`fa fa-bullseye ${showAnnotations ? 'icon-active' : ''}`} onClick={this.toggleShowAnnotations}></i>
        </div>}
      </div>

      { showAnnotations  && (
      <div className="popovers">
        { popovers.map((popover, i) => (
          <Popover key={i} title={popover.title} dx={popover.dx} dy={popover.dy}
            image={this.photo}
            measureCount={this.measureCount}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
          />
        ))}
      </div>
      )}

    </div>
  }
}

Photo.propTypes = {
  // src: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  sideInfo: PropTypes.bool,
};

Photo.defaultProps = {
  sideInfo: false,
  annotations: [],
}
