import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
// import { reducer as formReducer } from 'redux-form'
import createSagaMiddleware from 'redux-saga'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import { makeAppsReducers, makeAppsSaga } from 'redux-rocketjump'
import * as paintings from './paintings'

const APPS = {
  paintings,
}

const rootReducer = combineReducers({
  // form: formReducer,
  ...makeAppsReducers(APPS),
})

const mainSaga = makeAppsSaga(APPS)
const preloadedState = {}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(
    applyMiddleware(thunkMiddleware, sagaMiddleware),
  )
)
sagaMiddleware.run(mainSaga)

export default store
