import React from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavbarText,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap'

export default class Example extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render() {
    return (
      <div>
        <Navbar color="white" light expand="md" fixed="bottom">
          <Container>
            <Nav className="ml-auto mr-auto" navbar>
              <NavbarBrand>
                <small className="text-muted footer-small">&copy; Francesco Betti 2019</small>
              </NavbarBrand>
            </Nav>
          </Container>
        </Navbar>
      </div>
    )
  }
}
