import React from 'react'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import { Container } from 'reactstrap'

export default class About extends React.PureComponent {
  render() {
    return <div className="with-topbar with-footer min-100vh bg-light">
      <TopBar/>
      <Container className="py-5">
        <h3>About me</h3>
        <div className="row">
          <div className="col-md-6 p-3 text-justify">
            <p>
              Nasce il 23 febbraio 1980. Dopo aver frequentato il liceo artistico di Bergamo, dove gli saranno cari gli insegnamenti del pittore Gianfranco Bonetti, si iscrive all’Accademia Carrara di Belle Arti. Qui sarà allievo dell’artista Adrian Paci. Ottenuta la laurea nel 2003, Betti continua a dipingere per passione e all’interno di tableaux di medio e piccolo formato dispiega e racchiude il suo mondo figurativo e poetico. La sublimazione di un’emozione, di uno stato d’animo personale e subitaneo mediante il colore il gesto e la forma, il piacere e l’emozione generati dal vedere animarsi sulla tela paesaggi onirici. Il suo studio si trova a Bergamo in via Stezzano, 47
            </p>
          </div>
          <div className="col-md-6">
            {/* <img className="img-fluid" src="https://source.unsplash.com/collection/190727/800x600"/> */}
          </div>
        </div>


      </Container>
      <Footer/>
    </div>
  }
}
