import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import Photo from '../components/Photo'
import { Link } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import { loadArtwork, unloadArtwork, getArtwork, getArtworkLoading } from '../state/paintings'
import { loadCollection, unloadCollection, getCollection } from '../state/paintings'
import IosArrowDropright from 'react-ionicons/lib/IosArrowDropright'
import IosArrowDropleft from 'react-ionicons/lib/IosArrowDropleft'
import memoize from 'memoize-one'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import MetaTags from 'react-meta-tags';



class Artwork extends React.PureComponent {

  componentDidMount() {
    const { match } = this.props
    const { params } = match
    this.props.loadArtwork(params.slug)
    this.props.loadCollection(params.collectionSlug)
  }

  componentWillUnmount(){
    this.props.unloadArtwork()
    this.props.unloadCollection()
  }

  componentDidUpdate(oldProps) {
    const { match } = this.props
    const { params } = match
    if(oldProps.match.params.slug !== params.slug){
      this.props.loadArtwork(params.slug)
    }
    if(oldProps.match.params.collectionSlug !== params.collectionSlug){
      this.props.loadCollection(params.collectionSlug)  
    }
  }

  getPrevNext = memoize((artwork, collection) => {
    if(!artwork || !collection){
      return { prev: null, next: null}
    }
    const artworks = get(collection, 'artworks', [])
    const currentIdx = findIndex(artworks, item => item.id === artwork.id)
    const prev = currentIdx === -1 || currentIdx === 0 
      ? null
      : `/collections/${collection.slug}/artwork/${artworks[currentIdx-1].slug}`

    const next = currentIdx === -1 || currentIdx === artworks.length - 1
      ? null
      : `/collections/${collection.slug}/artwork/${artworks[currentIdx+1].slug}`

    return { prev, next}
  })

  render() {
    const { artwork, collection, artworkLoading,} = this.props
    const { prev, next } = this.getPrevNext(artwork, collection)

    return <Container
        className="with-topbar with-footer h-100vh d-flex flex-column">

      {artwork && <MetaTags>
          <title>{artwork.title}</title>
          <meta name="description" content={artwork.description}/>
          <meta property="og:title" content={artwork.title} />
          <meta property="og:image" content={artwork.image} />
        </MetaTags>}
      
      <TopBar/>
      
      { artwork && <React.Fragment>
        <Photo
            src={!artworkLoading ? artwork.image : undefined}
            title={artwork.title}
            description={artwork.description}
            location={``}
            sideInfo={false}
        />
        {/* {artworkLoading && <Spinner/>}  */}
        

        <div className="mt-3 artwork-info">
            <div className="artwork-arrow-left">
              {prev && <Link to={prev}><IosArrowDropleft  fontSize="28px"/></Link>}
            </div>
            <div className="text-center p-2 info">
              <h3>{artwork.title || <span>&nbsp;</span>}</h3>
              
              <div>
              {artwork.info && <small>{artwork.info}</small>}
              </div>
              <div>
              {artwork.year && <small>{artwork.year}</small>}
              </div>
            </div>
            <div className="artwork-arrow-right">
              {next && <Link to={next}><IosArrowDropright  fontSize="28px"/></Link>}
            </div>

        </div>
        
        </React.Fragment>}

      <Footer/>
    </Container>
  }
}

const mapStateToProps = state => ({
  artwork: getArtwork(state),
  artworkLoading: getArtworkLoading(state),
  collection: getCollection(state),
})

export default connect(
  mapStateToProps,
  { loadArtwork, unloadArtwork, loadCollection, unloadCollection, }
)(Artwork)
