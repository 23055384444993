import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import Collection from '../components/Collection'
import { Container } from 'reactstrap'
import { loadCollections, unloadCollections, getCollections } from '../state/paintings'


class Works extends React.PureComponent {

  componentDidMount() {
    this.props.loadCollections()
  }

  render() {
    const { collections } = this.props
    
    return <Container
        className="with-topbar with-footer d-flex flex-column align-items-center
        justify-content-center min-100vh">
      <TopBar/>

      <div className="row">

          {collections && collections.length > 0 && collections.map((collection, i) => <div key={i} className="col-md-6 my-2">
            <Collection collection={collection} />
            </div>
          )}
      </div>

      <Footer/>
    </Container>
  }
}

const mapStateToProps = state => ({
  collections: getCollections(state),
})

export default connect(
  mapStateToProps,
  { loadCollections, unloadCollections }
)(Works)
