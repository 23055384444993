import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './state'
import Home from './pages/Home'
import Works from './pages/Works'
import About from './pages/About'
import Contacts from './pages/Contacts'
import Collection from './pages/Collection'
import Artwork from './pages/Artwork'


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/works" exact component={Works}/>
            <Route path="/collections/:slug" exact component={Collection}/>
            <Route path="/collections/:collectionSlug/artwork/:slug" exact component={Artwork}/>
            <Route path="/about" exact component={About}/>
            <Route path="/contacts" exact component={Contacts}/>
            <Route path="/" exact component={Home}/>
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
