import React from 'react'
import { Link } from 'react-router-dom'
import chunk from 'lodash/chunk'
import './Collection.scss'


export default class Collection extends React.PureComponent {
  render(){
    const { collection } = this.props
    const { title, description, slug, artworks } = collection
    const chunkedArtworks = chunk(artworks, 3)
    return <Link className="collection-link" to={`/collections/${slug}`}><div className="h-100 d-flex flex-column align-items-center justify-content-center p-5 m-2">
      <div className="mb-2">
      {chunkedArtworks.length > 0 && chunkedArtworks.map((c, i) => (
        <div key={i} className="d-flex align-items-center justify-content-center">
          {c.map(artwork => <div key={artwork.id} className="artwork-mini">
              <img src={artwork.thumbnail} alt={artwork.title}></img>
            </div>)}
        </div>
      ))}
      </div>
      <h3 className="collection-title">{title}</h3>
      {description && <p className="text-center">{description}</p>}
    </div></Link>
  }
}
