import React from 'react'
import { connect } from 'react-redux'
import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import { loadCollections, unloadCollections, getCollections } from '../state/paintings'
import get from 'lodash/get'
import shuffle from 'lodash/shuffle'
import white from './white.png'
import Slider from "react-slick";
import { height } from 'window-size';


class Home extends React.PureComponent {

  state = {
    artworks: [],
  }

  componentDidMount() {
    this.props.loadCollections()
  }

   

  componentDidUpdate(oldProps){
    if(this.props.collections && oldProps.collections !== this.props.collections){
      const { collections } = this.props
      const artworks = collections
        ?  shuffle(collections.reduce((out, coll) => out.concat(coll.artworks), []))
        : []
      this.setState({artworks})
    }
  }


  render() {

    const {  artworks } = this.state
    const forSlideShow = artworks || []
    console.log(1, forSlideShow)
    
    const settings = {
      fade: true,
      infinite: true,
      speed: 1500,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    
    return <div
      
      className="with-topbar with-footer h-100vh bg-white d-flex flex-column align-items-center justify-content-around">
      <TopBar/>
      <div className="home-container flex-1 w-100 d-flex flex-column ">

      {forSlideShow.length > 0 && <Slider {...settings} className="flex-1 home-slider d-flex home-slides">
          
          {forSlideShow.map(artwork => <div className="position-absolute h-100" key={artwork.id}>
            <div style={{backgroundImage: `url(${artwork.image})`, backgroundPosition: 'center',
               height: '100%', backgroundSize: 'cover'}}></div>
          </div>)}

        </Slider>}

        <div className="home-texts flex-1 flex-column justify-content-end">
          {/* <div className="big-name text-center">
            {'Francesco Betti'.split('').map((c, i) => <Link key={i} to={'/works'}>{c}</Link>)}
          </div> */}
          <div>
            <div className="d-flex justify-content-around mb-2">
              <Link className="btn btn-outline-dark bg-light" to="/works">See my works</Link>
            </div>

            {/* <div className="border border-dark p-2 bg-light m-2 rounded">
              <h4 className="home-text mb-2 mt-2">Francesco Betti</h4>
            </div> */}

            <div className="d-flex justify-content-around">
              <Link className="btn btn-outline-dark bg-light" to="/contacts">Contact me</Link>
            </div>
          </div>
          

        </div>
        
        
      </div>
      <Footer/>
    </div>
  }
}

const mapStateToProps = state => ({
  collections: getCollections(state),
})

export default connect(
  mapStateToProps,
  { loadCollections, unloadCollections }
)(Home)
